export const HomaPageData = {
  news: {
    title: { "en-in": "Temple News", "te-in": "టెంపుల్ న్యూస్" },
    list: {
      "en-in": "AEE RESULT NOTIFICATION. ",
      "te-in": "AEE ఫలితాల నోటిఫికేషన్",
    },
    link: { "en-in": "Download", "te-in": "డౌన్లోడ్" },
    button: { "en-in": "View All", "te-in": "కొనసాగించండి" },
  },
  devoteeServices: {
    title: { "en-in": "Devotee Services", "te-in": "భక్తుని సేవలు" },
    seva: {
      "en-in": {
        title: "Seva",
        data: `Connect and perform Seva/Abhishekam/Homam, etc from anywhere using Paroksha Seva.`,
        buttonLable: "Book Now",
      },
      "te-in": {
        title: "సేవ",
        data: "పరోక్ష సేవకు కనెక్ట్ అవ్వండి, సేవ/అభిషేకం/హోమం మొదలగు పూజా కార్యక్రమాలలో ఎక్కడ నుండి అయినా పాల్గొనండి",
        buttonLable: "క్లిక్ చేయండి",
      },
    },
    eHundi: {
      "en-in": {
        title: "E-Hundi",
        data: `Contribute to the welfare of Indian Temples through donations.`,
        buttonLable: "Donate Now",
      },
      "te-in": {
        title: "ఈ - హుండీ",
        data: "మన దేవాలయాల పరిరక్షణకై ఈ-హుండీ ద్వారా విరాళాలు ఇవ్వండి.",
        buttonLable: "క్లిక్ చేయండి",
      },
    },
    accommodation: {
      "en-in": {
        title: "Accommodation",
        data: `Make your pilgrimage hassle-free by booking accommodation in advance.`,
        buttonLable: "Book Now",
      },
      "te-in": {
        title: "వసతి",
        data: "మీ తీర్థయాత్రకు ఎలాంటి అవధులు లేకుండా సాగించేందుకు ముందుగానే మీ వసతి బుక్ చేసుకోండి.",
        buttonLable: "క్లిక్ చేయండి",
      },
    },
    maaOoru: {
      "en-in": {
        title: "Maa Vooru Maa Gudi",
        data: `Take the step to initiate the development/renovation of your beloved village temple.`,
        buttonLable: "Donate Now",
      },
      "te-in": {
        title: "మా ఊరు మా గుడి",
        data: "మన ఊరిలోని దేవాలయాలను మనమే పునరుద్ధరించేందుకు మీ విరాళంతో అడుగులు ముందుకు వేయండి.",
        buttonLable: "క్లిక్ చేయండి",
      },
    },
  },
  liveVide: {
    "en-in": {
      titleLineOne: "Channel Videos",
      titleLineTwo: "From Temples",
      data: "Watch the sacred events happening at the Indian temple of your choice.",
      buttonLable: "View All temples",
    },
    "te-in": {
      titleLineOne: "నిత్య పూజల",
      titleLineTwo: "వీడియోలు",
      data: "మీరు కొలిచే దైవం కొలువున్న దేవాలయంలో జరిగే కైంకర్యాలను వీడియోలలో వీక్షించండి. ",
      buttonLable: "కొనసాగించండి",
    },
  },
  gallery: {
    title: { "en-in": "Photo Gallery", "te-in": "గ్యాలరీ" },
    buttonLable: { "en-in": "View Now", "te-in": "కొనసాగించండి" },
  },
  department: {
    title: {
      "en-in": "Government of Andhra Pradesh - Endowment Department",
      "te-in": "దేవాదాయ ధర్మాదాయ శాఖ - ఆంధ్ర ప్రదేశ్ ప్రభుత్వం",
    },
    personOne: {
      title: {
        "en-in": "Hon’ble Chief Minister of Andhra Pradesh",
        "te-in": "గౌరవ ముఖ్య-మంత్రి ఆంధ్రప్రదేశ్",
      },
      name: {
        "en-in": "Sri Nara Chandrababu Naidu",
        "te-in": "శ్రీ నారా చంద్రబాబునాయుడు",
      },
    },
    personTwo: {
      title: {
        "en-in": "Hon’ble Minister for Endowments, Andhra Pradesh",
        "te-in": "గౌరవ దేవాదాయ ధర్మాదాయ శాఖ మంత్రివర్యులు ఆంధ్రప్రదేశ్",
      },
      name: {
        "en-in": "Sri Anam Ramanarayana Reddy",
        "te-in": "శ్రీ ఆనం రామనారాయణరెడ్డి",
      },
    },
    personThree: {
      title: {
        "en-in": "Secretary, Revenue Endowments Department",
        "te-in": "కార్యదర్శి, రెవెన్యూ ఎండోమెంట్స్ డిపార్ట్‌మెంట్",
      },
      name: {
        "en-in": "Sri Vadarevu Vinay Chand, IAS",
        "te-in": "శ్రీ వాడరేవు వినయ్ చంద్, IAS",
      },
    },
    personFour: {
      title: {
        "en-in": "Commissioner of Endowments Andhra Pradesh",
        "te-in": "కమిషనర్ దేవాదాయ ధర్మాదాయ శాఖ ఆంధ్రప్రదేశ్",
      },
      name: {
        "en-in": "Sri K. Ramachandra Mohan",
        "te-in": "శ్రీ కె. రామచంద్ర మోహన్",
      },
    },
  },
};
